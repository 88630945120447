<template>
  <div class="c-app flex-row align-items-center">
    <CContainer>
      <CRow class="justify-content-center mb-5">
        <div class="text-center">
          <img src="/img/rankomat-logo.png" alt="Rankomat">
        </div>
      </CRow>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-4">
              <CCardBody>
                <CForm @submit.prevent="submit">
                  <h1>KPI Dashboard Login</h1>
                  <p class="text-muted">Sign In with your Office365 login (login@bauer.pl) and password</p>
                  <CInput
                    placeholder="Login"
                    autocomplete="username email"
                    v-model="form.email"
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    placeholder="Password"
                    type="password"
                    autocomplete="curent-password"
                    v-model="form.password"
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CAlert color="danger" v-if="credentialsError">
                    Email or Password is incorrect
                  </CAlert>
                  <CRow>
                    <CCol col="6" class="text-left">
                      <CButton color="primary" type="submit" class="px-4">Login</CButton>
                    </CCol>
                  </CRow>
                </CForm>
                <CAlert class="mt-5 text-center" color="danger" v-if="otherError">
                  Something went wrong. Please try again.
                </CAlert>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: 'Login',
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      credentialsError: false,
      otherError: false,
    };
  },
  methods: {
    ...mapActions({
      LogIn: "auth/LogIn"
    }),
    async submit() {
      const User = {
        identifier: this.form.email,
        password: this.form.password
      }
      await this.LogIn(User).then(result => {
        this.$router.push({name: 'Dashboard'});
        this.showError = false
      }).catch(error => {
        if (error.response.status == 401) {
          this.credentialsError = true
        } else {
          this.otherError = true
        }
      });
    },
  },
}
</script>

<style scoped>
.btn-google {
  background-color: #4285F4;
  color: #fff;
  padding: 1px 10px 1px 1px;
  font-weight: bold;
}
.google-logo {
  height: 36px;
  margin-right: 8px;
  padding: 6px;
  background-color: #fff;
}
</style>
